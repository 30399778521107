import React from 'react';
import type { OptimizelySubitoProvider as OptimizelySubitoProviderType } from '@sbt-web/houston-wrapper';
import dynamic from 'next/dynamic';
import { isBrowser } from '@sbt-web/utils';

const OptimizelySubitoProvider = dynamic(
  () =>
    import('@sbt-web/houston-wrapper').then(
      (mod) => mod.OptimizelySubitoProvider
    ),
  {
    ssr: false,
  }
);

export function ConditionalOptimizelySubitoProvider({
  children,
  ...restProps
}: Parameters<typeof OptimizelySubitoProviderType>[0]) {
  return isBrowser() ? (
    <OptimizelySubitoProvider {...restProps}>
      {children}
    </OptimizelySubitoProvider>
  ) : (
    children
  );
}
