'use client';

import { ViewportProvider } from '@sbt-web/hooks';
import React, { type ReactNode } from 'react';

const Provider = ({ children }: { children: ReactNode }) => (
  <ViewportProvider defaultType="mobile">{children}</ViewportProvider>
);

export default Provider;
