'use client';

import { advScripts } from '@sbt-web/adv';
import { getPublicUser } from '@sbt-web/auth';
import Footer from '@sbt-web/footer';
import { HeadProperties } from '@sbt-web/head-properties';
import Header from '@sbt-web/header';
import { createOptimizelyInstance } from '@sbt-web/houston-wrapper';
import { ENV } from '@sbt-web/utils';
import dynamic from 'next/dynamic';
import React, { type PropsWithChildren } from 'react';
import { SubitoScripts } from '../components/SubitoScripts';
import { DataLayerReadyProvider } from '../components/SubitoScripts/DataLayerContext';
import WebVitals from '../components/WebVitals';
import {
  clickFooterTracking,
  clickHeaderTracking,
  clickNewAdTrackingFromMenu,
  getTrackingInstance,
} from '../helpers/PulseTracking';
import ViewportProvider from '../providers/viewport';

import '@sbt-web/ui/dist/index.css';
import '@sbt-web/footer/dist/index.css';
import '@sbt-web/header/dist/index.css';
import './layout.scss';
import { ConditionalOptimizelySubitoProvider } from '../providers/ConditionalOptimizelySubitoProvider';

const DidomiWrapper = dynamic(() => import('../components/didomi-wrapper'), {
  ssr: false,
});

const optimizely = createOptimizelyInstance(
  process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT as ENV
);

export default function RootLayout({ children }: Readonly<PropsWithChildren>) {
  return (
    <html lang="it">
      <head>
        <title>Subito - Compra e vendi gratis in sicurezza</title>
        <link rel="preconnect" href={process.env.NEXT_PUBLIC_ASSETS_BASE} />
        <link
          href={process.env.NEXT_PUBLIC_HOUSTON_DATAFILE_URL}
          rel="preload"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="manifest"
          href={`${process.env.NEXT_PUBLIC_ENV_ORIGIN}/static/manifest.json`}
        />
        {advScripts({
          config: {
            includeAdSense: false,
            includeOw: false,
            includeTam: false,
            publicPath: '/ekhaya/script',
          },
        })}
        <DidomiWrapper />
        <HeadProperties assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: 'Subito',
              description:
                'Su Subito compri e vendi di tutto, a distanza e in modo sostenibile. Con il pagamento protetto fai affari sempre sicuri e spedisci in tutta Italia.',
              logo: `${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/logos/corporate-short.svg`,
              url: 'https://www.subito.it',
              sameAs: [
                'https://twitter.com/Subitoit/',
                'https://www.linkedin.com/company/subito-it/',
                'https://www.facebook.com/subitoit/',
                'https://www.youtube.com/@subitoit/',
                'https://www.instagram.com/subito_it/',
                'https://www.tiktok.com/@subito_it/',
              ],
              address: {
                '@type': 'PostalAddress',
                streetAddress: 'Via B. Crespi 19',
                addressLocality: 'Milano',
                postalCode: '20159',
                addressCountry: 'IT',
              },
            }),
          }}
        />
      </head>
      <body>
        <WebVitals />
        <DataLayerReadyProvider>
          <SubitoScripts />
          <ConditionalOptimizelySubitoProvider
            optimizely={optimizely}
            environmentId={getTrackingInstance()?.getEnvironmentId()}
            isServerSide={typeof window === 'undefined'}
          >
            <ViewportProvider>
              <div id="dfp_overlay_homepage"></div>
              <Header
                section="home"
                user={getPublicUser() ?? { id: '', name: '' }}
                urlPrefixes={{
                  baseURL: process.env.NEXT_PUBLIC_ENV_ORIGIN,
                  assetsBase: process.env.NEXT_PUBLIC_ASSETS_BASE,
                  reservedArea: process.env.NEXT_PUBLIC_RESERVEDAREA_BASE,
                  phpBase: process.env.NEXT_PUBLIC_PHP_BASE,
                  shopBase: process.env.NEXT_PUBLIC_IMPRESAPIU_BASE,
                }}
                onClickHandlers={{
                  adButton: () => {
                    clickNewAdTrackingFromMenu();
                  },
                  onClickItem: clickHeaderTracking,
                }}
                pulseInstance={getTrackingInstance()}
              />

              <main>{children}</main>
              <Footer
                type="full"
                assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE}
                onClickItem={clickFooterTracking}
              />
            </ViewportProvider>
          </ConditionalOptimizelySubitoProvider>
        </DataLayerReadyProvider>
      </body>
    </html>
  );
}
